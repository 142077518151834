<template>
	<div>
		<v-form ref="form" v-model="valid">
			<v-text-field
				label="異常の内容を記入してください"
				v-model="ListStatus[1].report"
				:rules="[(v) => !!v || '内容を入力してOKボタンを押してください']"
			></v-text-field>
		</v-form>
		<v-row class="ma-0">
			<v-col class="text-center text-sm-left">
				<v-btn
					outlined
					color="#262626"
					style="text-transform: none"
					class="rounded-0 mr-2"
					@click="deleteKizaiItem(item)"
					>Close</v-btn
				>
				<v-btn
					outlined
					color="#262626"
					style="text-transform: none"
					class="rounded-0"
					@click="clearKizaiItem(item)"
					>Clear</v-btn
				>
			</v-col>
			<v-col class="text-center text-sm-right">
				<v-btn
					outlined
					color="#262626"
					style="text-transform: none"
					class="rounded-0"
					@click="
						pushKizaiItem(item);
						sendrowClicked();
					"
					:disabled="!valid"
					>OK</v-btn
				>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	export default {
		name: "ReturnMenu",
		props: { ListStatus: Array },
		components: {},
		methods: {
			deleteKizaiItem: function() {
				this.$store.commit("set_dialogInfo_return_return", false);
			},
			clearKizaiItem: function() {
				this.$refs.form.reset();
			},
			pushKizaiItem: function() {
				const selected = this.ListStatus.indexOf();
				const saveitems = this.ListStatus[selected + 2];
				// console.log(this.ListStatus);

				// this.statusArr.push({
				// 	key: saveitems.model,
				// 	value: this.statusText,
				// });

				this.statusArr.push({
					id: saveitems.id,
					key: saveitems.model,
					value: this.ListStatus[1].report,
				});

				// console.log(this.statusArr);

				const convObj = this.statusArr;

				const Arr = convObj.map((item) => {
					return { id: item.id, key: item.key, value: item.value };
				});

				this.statusSend = Arr;
				// console.log(Arr);

				// console.log(this.statusArr);
				// console.log(this.statusText);
				this.$emit("addStatus", this.ListStatus[1].report, saveitems);
				this.$store.commit("set_dialogInfo_return_return", false);
			},
			sendrowClicked() {
				const selected = this.ListStatus.indexOf();
				const saveitems = this.ListStatus[selected + 1];

				this.$emit("sendrowClicked", saveitems, this.statusSend);
			},
		},
		computed: {
			dialogInfo_return_return() {
				return this.$store.getters.get_dialogInfo_return_return;
			},
		},
		data: () => ({
			headers2: [
				{ text: "Category", value: "category" },
				{ text: "Model", value: "model" },
				{ text: "Manufacturer", value: "manufacturer" },
				{ text: "Location", value: "location_repo" },
				{ text: "Box", value: "location_box" },
				{ text: "Size", value: "size" },
				{ text: "Modelnumber", value: "model_number" },
				{ text: "Number", value: "quantity", sortable: false },
				{
					text: "Status",
					value: "statuschange",
					sortable: false,
				},
			],

			valid: false,
			ListStatus: [],
			statusText: "",
			statusArr: [],
			statusSend: [],
		}),
	};
</script>
